import * as React from "react";
import { Link } from "gatsby";
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Models
import { IArticle } from "models/Article";
// Styles
import * as styles from "./ArticleItem.module.css";

type ArticleItemProps = {
  article: IArticle;
  wip?: boolean;
};

const ArticleItem: React.FC<ArticleItemProps> = ({ article, wip }) => {
  const { date, description, title, thumbnail } = article.frontmatter;
  const image = thumbnail
    ? getImage(thumbnail.childImageSharp.gatsbyImageData)
    : undefined;
  const slug = wip
    ? `wip${article.fields.slug}`
    : `articles/${article.fields.slug}`;

  return (
    <article className={styles.article_item}>
      <Link to={`/${slug}`} aria-label={title}>
        {image ? (
          <GatsbyImage
            className={styles.article_item__thumbnail}
            image={image}
            alt={title}
          />
        ) : (
          <StaticImage
            className={styles.article_item__thumbnail}
            src={"../../../images/default.webp"}
            alt={title}
          />
        )}
      </Link>
      <div className={styles.article_item__container}>
        <h2 className={styles.article_item__title}>
          <Link to={`/${slug}`}>{title}</Link>
        </h2>
        <small className={styles.article_item__date}>
          {/* Escrito por <Link to={`/users/${writer}`}>{writer}</Link> el {date} */}
          Artículo publicado el {date}
        </small>
        <p className={styles.article_item__excerpt}>{description}</p>
        <Link className={styles.article_item__view_more} to={`/${slug}`}>
          Leer artículo <FontAwesomeIcon icon={["fas", "chevron-right"]} />{" "}
        </Link>
      </div>
    </article>
  );
};

export default ArticleItem;
