import * as React from "react"
import { Link } from "gatsby"

// Components
import Sidebar from "components/Layout/Sidebar"
import Follow from "components/UIElements/Follow"

type ArticlesSidebarProps = {
  tags: [{ slug: string; count: number }]
}

const ArticlesSidebar: React.FC<ArticlesSidebarProps> = ({ tags }) => {
  return (
    <Sidebar>
      <h2 className="title">Categorías</h2>
      <section className={"sidebar_list"}>
        {tags.map(tag => {
          const name = tag.slug.charAt(0).toUpperCase() + tag.slug.slice(1)
          return (
            <Link key={tag.slug} className={"sidebar_list__element"} to={`/tags/${tag.slug}`}>
              <h3 className={"sidebar_list__element_title"}>{name}</h3>
              <div className={"sidebar_list__element_count"}>
                <span>{tag.count}</span>
              </div>
            </Link>
          )
        })}
      </section>
      <hr />
      <section className={"sidebar_card_alt"}>
        <h2 className="title">Síguenos</h2>
        <Follow />
      </section>
    </Sidebar>
  )
}

export default ArticlesSidebar
